<template>
  <section class="container">
    <section class="business__view">
      <div class="business__banner" v-if="businessData.catinfo">
        <figure><img :src="businessData.catinfo.thumb" alt=""/></figure>
        <strong>{{businessData.catinfo.name}}</strong>
      </div>
      <div class="business__btns inner">
        <div class="business__btns--item" :class="{'active':itemClass.catid == $route.params.catid}" v-for="(itemClass, index) in businessClass.list" :key="itemClass.catid">
          <router-link :to="`/businessView/${itemClass.catid}`">
            <b v-if="index == 0"><img src="@/assets/images/icon1.png" alt=""/></b>
            <b v-if="index == 1"><img src="@/assets/images/icon2.png" alt=""/></b>
            <b v-if="index == 2"><img src="@/assets/images/icon3.png" alt=""/></b>
            <span>{{itemClass.name}}</span>
          </router-link>
        </div>
      </div>
      <div class="business__tabs inner" id="business">
        <swiper :options="swiperOption" ref="mySwiper">
          <swiper-slide class="businessd-items" v-for="items in businessData.list" :key="items.catid">
            <div class="views__titles">
                  <h3>{{items.title}}</h3>
                  <strong>公司简介</strong>
                </div>
            <div class="business__tops">
              <div class="views__infos">
                
                <div class="views__texts" v-html="items.content"></div>
              </div>
              <div class="views__imgs">
                <figure><img :src="items.thumb" alt=""/></figure>
              </div>
            </div>
            <div class="business__service" v-if="items.server.length > 0">
              <h3 class="service__tt">产品与服务</h3>
              <ul class="service__list">
                <li v-for="itemService in items.server" :key="itemService.title">
                  <strong>{{itemService.title}}</strong>
                  <p v-html="itemService.description"></p>
                </li>
              </ul>
            </div>
            
          </swiper-slide>
          
        </swiper>
        <div class="business__prevs iconfont prev">&#xe608;</div>
        <div class="business__prevs iconfont next">&#xe607;</div>
      </div>
    </section>
    <Footer/>
  </section>
</template>

<script>
import Footer from '../components/Footer';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  data() {
    return {
      businessData: {},
      businessClass: {},
      swiperOption: {
        loop: true,
        speed:1000,
        parallax : true,
        autoHeight: true,
        autoplay: false,
        observeParents:false,   
        observer:true,
        // 显示分页
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
          nextEl: '.business__prevs.next',
          prevEl: '.business__prevs.prev',
        },
      }
    }
  },
  components:{
    Footer,
    swiper,
    swiperSlide
  },
  methods: {
    async getBusiness(){
      let catid = this.$route.params.catid;
      const {data: res} = await this.$http.get(`getBusinessInfoNew?catid= ${catid}`);
      const {data: res1} = await this.$http.post("getBusinessNew");
      if(res.status == 1){
          this.businessData = res.data;
      }
      if(res1.status == 1){
        this.businessClass = res1.data;
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    this.getBusiness();
  },
  watch: {
    $route(to, from) {
        this.getBusiness();
    }
  }
}
</script>

<style>

</style>