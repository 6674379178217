<template>
    <div class="news">
        <Pbanner v-if="NewsData.catinfo" :pbanner="NewsData.catinfo.thumb"/>
        <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/companynews">新闻资讯</router-link>
                <b>></b>
                <router-link to="/industrynews">行业新闻</router-link>
            </div>
            </div>  
        </div>
        <div class="hot-news" :style="{backgroundImage:`url(${HotNews})`}">
            <strong class="hot-title">行业新闻</strong>
            <div class="hot-tab" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
                <swiper class="hot-swiper" :options="swiperOption" ref="mySwiper" v-if='NewsData.newlist'>
                    <swiper-slide class="hot-item" v-for="(item, index) in listTemp" :key="index">
                        <div class="hot-item-left">
                            <div class="hot-item-info" v-for="sub in item.slice(0,2)" :key="sub.cid">

                                <router-link :to="'/companynews/'+sub.cid">
                                     <figure>
                                        <img :src="sub.thumb" :alt="sub.title"/>
                                    </figure>
                                    <div class="news-down">
                                        <time>{{sub.create_time}}</time>
                                        <strong>{{sub.title}}</strong>
                                        <p>{{sub.description}}</p>
                                    </div>
                                </router-link>
                               
                            </div>  
                        </div>
                        <div class="hot-item-right">
                             <div class="hot-item-info" v-for="sub in item.slice(2,4)" :key="sub.cid">
                                 <router-link :to="'/companynews/'+sub.cid">
                                     <figure>
                                        <img :src="sub.thumb" :alt="sub.title"/>
                                    </figure>
                                    <div class="news-down">
                                        <time>{{sub.create_time}}</time>
                                        <strong>{{sub.title}}</strong>
                                        <p>{{sub.description}}</p>
                                    </div>
                                 </router-link>
                            </div>  
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
        <div class="news-boxs">
            <ul class="news-list">
                <li v-for="item in newsList" :key="item.cid">
                    <router-link :to="'/companynews/'+item.cid">
                        <div class="news-imgs">
                            <figure><img :src="item.thumb" alt=""/></figure>
                        </div> 
                        <div class="news-text">
                            <time>{{item.create_time}}</time>
                            <strong>{{item.title}}</strong>
                            <p>{{item.description}}</p>
                        </div>
                    </router-link>
                </li>
            </ul> 
             <div class="user-pagination" v-if="newsList.length > 0">
                <el-button type="button" @click="FirstPage" class="my-btn">首页</el-button>
                <el-pagination ref="pagination"
                    background prev-text="上一页" next-text="下一页"
                    layout="prev, pager, next, slot"
                    @current-change="handleCurrentChange"
                    :current-page="queryInfo.page"
                    :total="total"
                    :page-sizes="pagesize"
                    >
                </el-pagination>
                <el-button type="button" @click="LastPage" class="my-btn" style="margin: 0px 5px">尾页</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    components: {
        Pbanner,
        Levalmenu,
        swiper,
        swiperSlide
    },
    data() {
        return {
            menuNumber: 3,
            NewsData: [],
            HotNews: require('../../assets/14.jpg'),
            HotList:[],
            newsList: [],
            queryInfo: {
                catid: 9,
                page: 1
            },
            total: 0,
            pagesize: 4,
            swiperOption: {
                loop: true,
                speed:1000,
                parallax : true,
                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observeParents:false,   
                observer:true,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            }
        }
    },
    methods: {
        async getCompanyNews(){
            var qs = require('qs');
            const {data: res} = await this.$http.post("getNews",qs.stringify(this.queryInfo));
            if(res.status == 1){
                this.NewsData = res.data;
                this.HotList = res.data.newlist;
                this.newsList = res.data.list;
                this.total = Number(res.data.pageinfo.count);
            }
        },
        handleCurrentChange(newPage){
            this.queryInfo.page = newPage;
            this.getCompanyNews();
           
        },
        on_bot_enter() {
            this.$refs.mySwiper.swiper.autoplay.stop()
        },
        on_bot_leave() {
            this.$refs.mySwiper.swiper.autoplay.start()
        },
        FirstPage(){
            this.$refs.pagination.handleCurrentChange(1);
            this.$emit('handleCurrentChange', 1);
        },
        LastPage(){
            let font = this.$refs.pagination
            let cpage = Math.ceil(font.total / font.pageSize);
            font.handleCurrentChange(cpage);
        }
    },
    mounted() {
        this.getCompanyNews();
    },
     computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
        listTemp: function () {
            var list = this.HotList;
            var arrTemp = [];
            var index = 0;
            var sectionCount = 4;
            for (var i = 0; i < list.length; i++) {
                index = parseInt(i / sectionCount);
                if (arrTemp.length <= index) {
                    arrTemp.push([]);
                }
                arrTemp[index].push(list[i]);
            }
            return arrTemp;
        }
    },
}
</script>

<style lang="less">
    .hot-news{
        padding: 88px 10.41% 100px;
        background-position: center;
        background-size: cover;
    }
    .hot-title{
        display: inline-block;
        position: relative;
        z-index: 3;
        line-height: 1;
        color: #121212;
        font-size: 38px;
    }
    .hot-tab{
        margin-top: 28px;
    }
    .hot-item{
        display: flex;
        justify-content: space-between;
    }
    .hot-item-left{
        display: flex;
        width: 68.15%;
        .hot-item-info{
            width: 50%;
            a{
                display: block;
                position: relative;
                margin-right: 40px;
                figure{
                    display: block;
                    position: relative;
                    padding-bottom: 56.04%;
                    line-height: 1;
                    overflow: hidden;
                    img{
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: 0.65s;
                    }
                }
            }
        }
    }
    .hot-item-right{
        position: relative;
        width: 31.85%;
        .hot-item-info{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            &:last-child{
                top: auto;
                bottom: 0;
            }
            figure{
                display: none;
            }
            .news-down{
                padding: 42px 40px 44px;
            }
        }
    }
    .news-down{
        padding: 27px 28px 42px;
        background: #fff;
        time{
            display: block;
            margin-bottom: 18px;
            line-height: 1;
            color: #e5000f;
            font-size: 16px;
        }
        strong{
            display: block;
            margin-bottom: 25px;
            line-height: 1.2;
            color: #333333;
            font-size: 18px;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: 0.38s;
        }
        p{
            color: #999999;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp:2;
            -webkit-box-orient: vertical;
            height: 56px;
        }
    }
    .hot-item-info:hover{
        a{
            figure img{
                transform: scale(1.06);
            }
            .news-down{
                strong{
                    color: #e5000f;
                }
            }
        }
    }
    .hot-swiper{
        position: relative;
        padding-top: 52px !important;
        margin-top: -52px;
        .swiper-pagination{
            left: auto;
            right: 0;
            left: auto !important;
            width: auto !important;
            top: 0 !important;
            bottom: auto !important;
            .swiper-pagination-bullet{
                margin-left: 10px;
                width: 10px;
                height: 10px;
                background: #ababab;
                transition: 0.36s;
                &.swiper-pagination-bullet-active{
                    background: #e5000f;
                }
            }
        }
    }
    .news-boxs{
        padding: 0 10.41% 80px;
    }
    .news-list{
        li{
            padding: 60px 0;
            border-bottom: 1px solid #e5e5e5;
            a{
                display: flex;
                justify-content: space-between;
                //flex-direction: row-reverse;
                align-items: center;
                &:hover{
                    figure img{
                        transform: scale(1.05);
                    }
                    .news-text{
                        strong{
                            color: #e5000f;
                        }
                    }
                }
            }
        }
    }
    .news-imgs{
        display: block;
        width: 31.57%;
        overflow: hidden;
        figure{
            display: block;
            line-height: 1;
            position: relative;
            padding-bottom: 56.04%;
            img{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: auto;
                transition: 0.65s;
            }
        }
    }
    .news-text{
        width: 63.28%;
        padding-top: 40px;
        time{
            display: block;
            line-height: 1;
            color: #e5000f;
            font-size: 16px;
        }
        strong{
            display: block;
            margin-top: 30px;
            margin-bottom: 22px;
            line-height: 1.2;
            color: #333333;
            font-size: 26px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: 0.4s;
        }
        p{
            color: #666666;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    .user-pagination{
        display: flex;
        justify-content: center;
        margin-top: 60px;
        .el-button{
            width: 63px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #dddddd;
            border-radius: 0;
            color: #666666;
            font-size: 12px;
            padding: 0;
            transition: 0.4s;
            &:hover{
                border-color: #e50012;
                background: #e50012;
                color: #fff;
            }
        }
         .el-pagination.is-background .el-pager li{
             background: none;
         }
         .el-pagination{
             padding: 0;
             padding-left: 3px;
         }
        .el-pager li{
            margin: 0 4px !important;
            width: 30px;
            height: 30px;
            border: 1px solid #dddddd;
            line-height: 28px !important;
            color: #666666;
            font-weight: normal;
            font-size: 12px;
            background: none;
            transition: 0.36s;
            padding: 0;
            border-radius: 0 !important;
            background: none;
            &:hover,&.active{
                color: #fff;
                border-color: #e50012;
                background: #e50012;
            }
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active{
            color: #fff;
                border-color: #e50012;
                background: #e50012;
        }
        .el-pagination.is-background .el-pager li:not(.disabled):hover{
            color: #fff;
                border-color: #e50012;
                background: #e50012;
        }
        .btn-prev,.btn-next{
            background: transparent !important;
        }
        .el-button:hover{
            color: #fff;
            border-color: #e50012;
            background: #e50012;
        }
        .el-button:focus{
             color: #666666;
            border-color: #dddddd;
            background: none;
        }
        .btn-prev span,.btn-next span{
            display: inline-flex;
            padding: 0 12px !important;
            height: 30px !important;
            line-height: 30px !important;
            border: 1px solid #dddddd;
            color: #666666;
            font-weight: normal;
            font-size: 12px !important;
            background: none;
            border-radius: 0px !important;
            background-color: transparent !important;
        }
        .el-pager li.active+li{
            border-left: 1px solid #ddd;
        }
    }
</style>